<template>
  <modal-card :title="'Manage delegates access'">
    <manage-participants :task-id="taskId" />
  </modal-card>
</template>

<script>
export default {
  name: "ManageParticipantsAccessModal",
  components: {
    "manage-participants": () => import("@shared/tasks/_manageParticipants.vue")
  },
  props: {
    taskId: {
      type: String,
      required: true
    }
  }
};
</script>
